import { Injectable } from '@angular/core';
import { Endpoints } from "../../endpoints";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatbotServiceService {
  httpOptions = {

    headers: new HttpHeaders({

        'Content-Type': 'application/json',

    })

}

  private userQueryUrl = `${Endpoints.serviceurls.chatbot.chatbotURL}`

  constructor(private http: HttpClient) { }

  getQueryResponse(data : any):Observable<any>{
    return this.http.get(this.userQueryUrl+`?employeeObjectId=${data.objectID}&query=${data.query}` , { responseType: 'text' }).pipe(catchError((error: any) => throwError(error)))
  }
}
