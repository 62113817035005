import { Routes } from '@angular/router';
import { LoginComponent } from './shared/login/login.component';
import { authGuard } from './guard/auth.guard';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';



export const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./shared/shared-managment.module').then(m => m.sharedManagmentModule)
  },

  {
    path: 'Leave',
    loadChildren: () => import('./leave-management/leave-managment.module').then(m => m.LeaveManagmentModule),
    canActivate: [authGuard]
  },
  {
    path: 'Asset',
    loadChildren: () => import('./assets-management/assets-management.module').then(m => m.AssestManagmentModule),
    canActivate: [authGuard]
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./layout/dashboard-managment/dashboard-managment.module').then(m => m.DashboardManagmentModule),
    canActivate: [authGuard]
  },
  {
    path: 'organization',
    loadChildren: () => import('./Organization-management/organization-management.module').then(m => m.OrganizationManagementModule),
    canActivate: [authGuard]
  },

  {
    path: 'hr',
    loadChildren: () => import('./hr-management/hr-managment.module').then(m => m.HrManagmentModule),
    canActivate: [authGuard]
  },

  {
    path: 'project',
    loadChildren: () => import('./project-management/project-management.module').then(m => m.ProjectManagmentModule),
    canActivate: [authGuard]
  },

  {
    path: 'attendance',
    loadChildren: () => import('./attendance-management/attendance-mamagement.module').then(m => m.AttendanceManagementModule),
    canActivate: [authGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    canActivate: [authGuard]
  },

  {
    path: 'employee',
    loadChildren: () => import('./employee-managment/employee-managment.module').then(m => m.EmployeeManagmentModule),
    canActivate: [authGuard]
  },

  {
    path: 'travel',
    loadChildren: () => import('./Travel-management/travel-management.module').then(m => m.TravelManagmentModule),
    canActivate: [authGuard]
  },
  {
    path: 'config',
    loadChildren: () => import('./Config-management/config-management.module').then(m => m.ConfigManagmentModule),
    canActivate: [authGuard]
  },
  {
    path: 'compensation',
    loadChildren: () => import('./compensation/compensation-routing.module').then(m => m.CompensationRoutingModule),
    canActivate: [authGuard]
  },
  {
    path: 'system-setting',
    loadChildren: () => import('./system-settings/system-settings.module').then(m => m.SystemSettingsModule),
    canActivate: [authGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports-routing.module').then(m => m.ReportsModule),
    canActivate: [authGuard]
  },
  {
    path: 'expenditure',
    loadChildren: () => import('./Travel-management/travel-management.module').then(m => m.TravelManagmentModule),
    canActivate: [authGuard]
  },
  {
    path: 'shifts',
    loadChildren: () => import('./shifts/shifts.module').then(m => m.ShiftsModule),
    canActivate: [authGuard]
  },

  { // keep this route path always at the end of the route array
    path: "**",
    component: PageNotFoundComponent
  },

];
