import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChatbotServiceService } from '../../services/chatbotService/chatbot-service.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-chatbot',
  standalone: true,
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  imports: [CommonModule, FormsModule],
})
export class ChatbotComponent implements OnInit {
  @ViewChild('chatMessages') chatMessagesContainer!: ElementRef;

  isOpen = false;
  iconClass = 'fas fa-comment'; // Default icon class
  userId: string = '';
  userQuery: string = '';
  chatHistory: { sender: string; message: string }[] = [];
  isTyping = false; // Flag to show typing animation
  hasNewNotification = false;

  constructor(private chatbotService: ChatbotServiceService) { }

  ngOnInit(): void {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      this.userId = parsedUser.empObjectId;
    }

    // Load chat history from localStorage
    const savedChatHistory = localStorage.getItem('chatHistory');
    if (savedChatHistory) {
      this.chatHistory = JSON.parse(savedChatHistory);
    }
  }


  // sendQuery() {
  //   if (!this.userQuery.trim()) return;

  //   const queryObject = {
  //     objectID: this.userId,
  //     query: this.userQuery,
  //   };

  //   this.chatHistory.push({ sender: 'User', message: this.userQuery });
  //   this.userQuery = ''; // Clear input
  //   this.saveChatHistory(); // Save updated chat history
  //   this.scrollToBottom(); // Scroll to the latest message



  //   this.isTyping = true; // Show typing animation

  //   this.chatbotService.getQueryResponse(queryObject).subscribe({
  //     next: (response: string) => {
  //       this.isTyping = false; // Stop typing animation
  //       this.chatHistory.push({ sender: 'Bot', message: response });
  //       this.saveChatHistory(); // Save updated chat history
  //       this.scrollToBottom();



  //       if (!this.isOpen) {
  //         this.hasNewNotification = true; // Show notification if minimized
  //       }
  //     },
  //     error: (err) => {
  //       this.isTyping = false;
  //       this.chatHistory.push({
  //         sender: 'Bot',
  //         message: 'Error fetching response. Please try again later.',
  //       });
  //       this.saveChatHistory(); // Save updated chat history
  //       this.scrollToBottom(); // Scroll to the latest message
  //     },
  //   });
  // }

  sendQuery() {
    if (!this.userQuery.trim()) return;
  
    const queryObject = {
      objectID: this.userId,
      query: this.userQuery,
    };
  
    this.chatHistory.push({ sender: 'User', message: this.userQuery });
    this.userQuery = ''; // Clear input
    this.saveChatHistory(); // Save updated chat history
    this.scrollToBottom(); // Scroll to the latest message
  
    this.isTyping = true; // Show typing animation
  
    this.chatbotService.getQueryResponse(queryObject).subscribe({
      next: (response: string) => {
        this.isTyping = false; // Stop typing animation
        const modifiedResponse = `${response}\nIs there anything I can assist you with?`;
        this.chatHistory.push({ sender: 'Bot', message: modifiedResponse });
        this.saveChatHistory(); // Save updated chat history
        this.scrollToBottom();
                                                
        if (!this.isOpen) {
          this.hasNewNotification = true; // Show notification if minimized
        }
      },
      error: (err) => {
        this.isTyping = false;
        this.chatHistory.push({
          sender: 'Bot',
          message: 'Error fetching response. Please try again later.\n\nIs there anything I can assist you with?',
        });
        this.saveChatHistory(); // Save updated chat history
        this.scrollToBottom(); // Scroll to the latest message
      },
    });
  }
  

  toggleChat() {
    this.scrollToBottom();
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.hasNewNotification = false; // Clear notification when opened
    }
  }


  saveChatHistory() {
    localStorage.setItem('chatHistory', JSON.stringify(this.chatHistory));
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.chatMessagesContainer) {
        const element = this.chatMessagesContainer.nativeElement;
        element.scrollTop = element.scrollHeight;
      }
    }, 0); // Timeout ensures DOM updates are complete
  }

  onMouseEnter() {
    this.iconClass = 'fas fa-pencil-alt';
  }

  onMouseLeave() {
    this.iconClass = 'fas fa-comment';
  }
}
