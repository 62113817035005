<div class="error-container"> 
    <h1> 404 </h1> 
    <p> 
        Oops! The page you're 
        looking for is not here. 
    </p> 
    <a (click)="redirectTo()">
        Go Back to Home 
    </a> 
</div>
