import { HttpInterceptorFn } from '@angular/common/http';

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken = localStorage.getItem('authToken');

  if (!authToken) {
    console.log("auth token is required")

  }
  // Clone the request and add the authentication token to the headers
  const authReq = req.clone({
    headers: req.headers.set('Authorization', `${authToken}`),
  });

  return next(authReq);
};
