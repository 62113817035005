import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ChatbotComponent } from './shared/chatbot/chatbot.component';
import { UserService } from './services/user.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ChatbotComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent {
  title = 'EM1st';

  constructor(private userService: UserService, @Inject(PLATFORM_ID) private platformId: Object) {}

  get isLoggedIn(): boolean {
    return this.userService.isLogged();
  }

  ngOnInit() {
    // Check if the code is running in the browser
    // if (isPlatformBrowser(this.platformId)) {
    //   window.addEventListener('beforeunload', this.clearLocalStorage);
    // }
    // if (isPlatformBrowser(this.platformId)) {
    //   // Check if sessionStorage already has the flag indicating the browser is closing
    //   if (!sessionStorage.getItem('sessionInitialized')) {
    //     // Set session flag to indicate first time load
    //     sessionStorage.setItem('sessionInitialized', 'true');
        
    //     // Clear localStorage only the first time during the session
    //     localStorage.clear();
    //   }
    //    // Add event listener to detect browser/tab close
    //    window.addEventListener('beforeunload', this.onBeforeUnload);
    // }
  }
  // ngOnDestroy() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     // Clean up the event listener when the component is destroyed
  //     window.removeEventListener('beforeunload', this.onBeforeUnload);
  //   }
  // }

  // onBeforeUnload() {
  //   // Save a flag in sessionStorage to track browser close
  //   // sessionStorage.setItem('browserClosed', 'true');
  //   // // Clear localStorage when the tab is about to be closed
  //   // localStorage.clear();
  // }

  // ngOnDestroy() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     window.removeEventListener('beforeunload', this.clearLocalStorage);
  //   }
  // }

  // clearLocalStorage() {
  //   localStorage.clear();
  // }
}
