import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {

  constructor(
    private userService: UserService,
    private router: Router
  ){}
  
  redirectTo(){
    this.router.navigate(this.userService.isLogged() ? ['/dashboard/employee']:['/login'])
  }
}
