<div
  class="chatbot-container"
  (click)="toggleChat()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.open]="isOpen"
  title="Chat with us!"
>
  <i
    class="chat-icon"
    [class]="userQuery.trim() ? 'fas fa-paper-plane' : iconClass"
    (click)="userQuery.trim() && sendQuery()"
  ></i>
  <div *ngIf="hasNewNotification && !isOpen" class="notification-badge">
    1
  </div>
</div>


<div class="chatbot-popup" *ngIf="isOpen">
  <div class="chatbot-header">
    <span>Hi There</span>
    <button class="close-btn" (click)="toggleChat()">&times;</button>
  </div>
  <div class="chatbot-body">
    <div class="chat-messages" #chatMessages>
      <div *ngFor="let chat of chatHistory">
        <div
          class="message-wrapper"
          [class.user]="chat.sender === 'User'"
          [class.bot]="chat.sender === 'Bot'"
        >
          <div class="user-image" *ngIf="chat.sender === 'User'"></div>
          <div class="message userTextBox" *ngIf="chat.sender === 'User'">
            {{ chat.message }}
          </div>
          <div class="bot-image" *ngIf="chat.sender === 'Bot'"></div>
          <div class="message bottextBox" *ngIf="chat.sender === 'Bot'">
            {{ chat.message }}
          </div>
        </div>
      </div>
      <!-- Bot Typing Animation -->
      <div class="bot-image" *ngIf="isTyping"></div>
      <div *ngIf="isTyping" class="typing-indicator">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="chat-input">
      <input
        type="text"
        [(ngModel)]="userQuery"
        placeholder="Type your question here..."
        (keyup.enter)="sendQuery()"
      />
    </div>
  </div>
  

  
</div>
 
 